<template>
  <div id="digitalCurrencyEquations">
    <div class="header">
      <PanelHeader title="هات وچرر" />
    </div>
    <div class="digital-currency-equations-container">
      <div class="second-row">
        <div class="column-one">
          <div class="tab-card">
            <div class="tab-header" :class="[left ? 'left' : '']">
              <div class="right-tab" @click="toggleRightSide">
                خرید هات ووچر
              </div>
              <div class="left-tab" @click="toggleLeftSide">
                فروش هات ووچر
              </div>
            </div>
            <div class="tab-right-content" v-if="!left">
              <div class="right-tab-content-wrapper">
                <BuyHotVoucher :item="crypto" />
              </div>
            </div>
            <div class="tab-left-content" v-if="left">
              <div class="left-tab-content-wrapper">
                <SellHotVoucher :item="crypto" />
              </div>
            </div>
          </div>
        </div>
        <div class="column-two">
          <div class="information-wrapper">
            <TipsAndWarnings />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PanelHeader from "@/components/PanelHeader";
import TipsAndWarnings from "@/components/TipsAndWarnings";
import BuyHotVoucher from "@/components/HotVoucherCard/BuyHotVoucher";
import SellHotVoucher from "@/components/HotVoucherCard/SellHotVoucher";
import axios from "axios";
// import TxidInformationModal from "@/components/Modals/TxidInformationModal";

export default {
  components: {
    SellHotVoucher,
    BuyHotVoucher,
    TipsAndWarnings,
    PanelHeader
  },
  data() {
    return {
      left: false,
      crypto: null
    };
  },
  mounted() {
    this.getHotVoucherInformation();
  },
  methods: {
    async getHotVoucherInformation() {
      // let loading = this.$loading.show();
      try {
        let response = await axios("/crypto?q=HOTV");
        this.crypto = response.data[0];
      } catch (error) {
        console.log(error.data());
      }
      // loading.hide();
    },
    toggleRightSide() {
      this.left = false;
    },
    toggleLeftSide() {
      this.left = true;
    }
  }
};
</script>

<style scoped>
#digitalCurrencyEquations {
  width: 100%;
  margin-bottom: 20px;
}

.digital-currency-equations-container {
  padding: 0 10px;
}

.header {
  margin-bottom: 20px;
}

.top-slider {
  width: 100%;
  min-height: 168px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background: #ffffff;
}

.slider-wrapper {
  min-height: 168px;
  display: flex;
  gap: 70px;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 81px;
}

.name {
  margin-top: 15px;
  font-weight: normal;
  font-size: 16px;
  color: rgba(18, 18, 18, 0.6);
}

.item-image {
  width: 60px;
}

.second-row {
  margin-top: 20px;
}

.tab-card {
  width: 100%;
  height: auto;
  /*min-height: 878px;*/
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  /*padding: 0 20px;*/
  padding: 0 5px;
}

.tab-header {
  display: flex;
  padding: 30px 0 13px 0;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  position: relative;
  cursor: pointer;
  /*width: 100%;*/
}

.tab-header::before {
  transition: right 0.2s linear, background-color 0.2s linear;
  content: "";
  display: block;
  height: 3px;
  width: 50%;
  background-color: #039100;
  position: absolute;
  bottom: 0;
  right: 0;
  /*border-bottom: 5px solid #1F3C88;*/
  border-radius: 5px;
  /*background: #DC0000;*/
}

.tab-header::before {
  content: "";
  display: block;
  height: 3px;
  width: 50%;
  background: rgba(7, 13, 89, 0.2);
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 5px;
  background: #1f3c88;
}

.tab-header.left::before {
  right: 50%;
  background-color: #dc0000;
}

.tab-header::after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background: rgba(3, 145, 0, 0.2);
  position: absolute;
  bottom: 1px;
}

.tab-right-content {
  /*padding: 20px 45px;*/
  padding: 5px 5px;
}

.tab-left-content {
  /*padding: 20px 45px;*/
  padding: 5px;
}

.second-row {
  display: flex;
  gap: 35px;
  flex-wrap: wrap;
}

.column-one {
  width: 100%;
}

.column-two {
  width: 100%;
}

.right-tab {
  width: 50%;
}

.left-tab {
  width: 50%;
}

.information-wrapper {
  /*width: 100%;*/
  /*min-height: 1149px;*/
  /*background: #ffffff;*/
  /*box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);*/
  /*border-radius: 10px;*/
}

/*.right-tab-content-wrapper {*/
/*  width: 100%;*/
/*  min-height: 743px;*/
/*  background: rgba(3, 145, 0, 0.1);*/
/*  border-radius: 10px;*/
/*}*/

.panel-footer {
  margin-top: 20px;
}

@media (min-width: 1140px) {
  #digitalCurrencyEquations {
    width: calc(100% - 250px);
    margin-right: auto;
  }

  .digital-currency-equations-container {
    padding: 0 40px;
  }
}

@media (min-width: 768px) {
  .tab-right-content {
    padding: 20px 45px;
  }

  .tab-left-content {
    padding: 20px 45px;
  }
}

@media (min-width: 960px) {
  .column-one {
    width: 58%;
  }

  .column-two {
    width: 38%;
  }

  .tab-card {
    padding: 0 20px;
  }
}
</style>
